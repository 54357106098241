import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import moment from 'moment';
import Button from 'components/Button';
import Select, { OptionItem } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import Checkbox from 'components/Inputs/Checkbox';
import { FormComponents, FormGeneratorProps, FormInput, ValuesInterface } from './form-generetor.scheme';
import useStyles from './form-generator.styles';
import { Trans, useTranslation } from 'react-i18next';
import { FormControl, Grid } from '@material-ui/core';
import { ErrorsScheme } from 'interfaces/main';
import Yup, { PasswordErrors } from 'yupSetup';
import Switch from 'components/Inputs/Switch/switch.render';
import DatePicker from 'components/Inputs/DatePicker';
import GoogleLocationAutocomplete from 'components/Inputs/GoogleLocationAutocomplete';
import Typography from 'components/Typography';
import RadioButtons from 'components/Inputs/RadioButtons';
import ButtonRadioGroup from 'components/Inputs/ButtonRadioGroup';
import classNames from 'classnames';
import { scrollToElement } from 'helpers/scrollToElement';
import { Link } from 'react-router-dom';
import Icon, { IconsType } from 'components/Icon';
import config from 'config/common';

export type FormGeneratorHandle = {
    handleSubmitClick: () => Promise<any>;
};

const FormGenerator = forwardRef<FormGeneratorHandle, FormGeneratorProps>((props, ref) => {
    const {
        inputs,
        errors,
        submitBtnT: submitBtnText,
        submitBtnBreakPoints,
        cancelBtnProps,
        cancelBtnT,
        cancelBtnBreakPoints,
        validationsScheme,
        color = 'primary',
        variant = 'outlined',
        submitBtnProps: btnProps,
        error,
        loading,
        isMaxWidthForm,
        customWrapperCss,
        disabled,
        onFieldChange,
        onSubmitClick,
        onCancelClick,
        submitBtnCenter,
        reverseSubmitCancelButtons,
        fullWidth,
        submitted,
        onErrors,
        onBlurTextField,
        gap,
    } = props;

    const { t } = useTranslation();
    const classes = useStyles({ gap });
    let inputsRefs = React.useRef<any>({});
    const [dataValues, setDataValues] = React.useState<any>({});

    React.useEffect(() => {
        const defaultValues = inputs.reduce((acc, input) => {
            let tempObj = { ...acc };
            switch (input.type) {
                case FormComponents.datepicker:
                    if (input.datepickerProps?.defaultValue) {
                        tempObj = {
                            ...tempObj,
                            [input.id]: moment
                                .utc(
                                    new Date(
                                        input?.datepickerProps?.defaultValue?.toLocaleString() || ''
                                    ).toLocaleDateString()
                                )
                                .toISOString(),
                        };
                    }
                    break;
                case FormComponents.googleLocationAutocomplete:
                    if (input.googleLocationAutocompleteDefaultObject) {
                        tempObj = { ...tempObj, [input.id]: input.googleLocationAutocompleteDefaultObject };
                    }
                    break;
            }
            return tempObj;
        }, {});
        setDataValues(defaultValues);
        //eslint-disable-next-line
    }, []);
    const [formErrors, setFormErrors] = React.useState<ErrorsScheme>({});

    React.useEffect(() => {
        setFormErrors({
            ...errors,
            ...formErrors,
        });
        //eslint-disable-next-line
    }, [errors]);

    React.useEffect(() => {
        if (submitted) {
            handleSubmitClick();
        }
        //eslint-disable-next-line
    }, [submitted]);

    const getAllValues = useCallback(() => {
        const values: ValuesInterface = Object.keys(inputsRefs.current).reduce((acc, field) => {
            const type = inputsRefs.current[field]?.type;
            let value = inputsRefs.current[field]?.value;

            switch (type) {
                case 'checkbox':
                    value = inputsRefs.current[field]?.checked;
                    break;
                case 'radio':
                    value = inputsRefs.current[field]?.value.split(',');
                    break;
                case 'number':
                    value = Number(inputsRefs.current[field]?.value);
                    break;
                case 'select-multiple':
                    try {
                        const selectedOptions = inputsRefs.current[field].selectedOptions;
                        const newValueArr: string[] = [];
                        for (let option of selectedOptions) {
                            newValueArr.push(option.value);
                        }
                        value = newValueArr;
                    } catch (error) {
                        value = [];
                    }
                    break;
            }
            try {
                if (typeof value === 'string') {
                    value = value.trim();
                }
            } catch (error) {}
            return {
                ...acc,
                [field]: value,
            };
        }, {});

        const allValues = { ...values, ...dataValues };

        return allValues;
    }, [dataValues]);

    const handleSubmitClick = React.useCallback(async () => {
        const allValues = getAllValues();

        if (validationsScheme && onSubmitClick) {
            const schema = Yup.object().shape(validationsScheme);

            try {
                const values = await schema.validate(allValues, { abortEarly: false });
                onErrors && onErrors(null);
                onSubmitClick(allValues);
                return values;
            } catch (err: any) {
                try {
                    const newErrorsObj = err.inner.reduce(
                        (acc, error) => ({
                            ...acc,
                            [error.path]: {
                                error: true,
                                error_label: error.message,
                            },
                        }),
                        { ...formErrors }
                    );
                    try {
                        const firstErrorElementRef = inputsRefs.current[Object.keys(newErrorsObj)[0]];
                        scrollToElement(firstErrorElementRef);
                    } catch (error) {}

                    setFormErrors(newErrorsObj);
                    onErrors && onErrors(newErrorsObj);
                } catch (error) {}
            }
        } else {
            onSubmitClick && onSubmitClick(allValues);
        }
    }, [getAllValues, validationsScheme, onSubmitClick, onErrors, formErrors]);

    useImperativeHandle(
        ref,
        () => ({
            handleSubmitClick,
        }),
        [handleSubmitClick]
    );

    const handleOnBlur = (e) => {
        const inputId = e.target.id;
        const value = e.target.value;
        handleOnChange(inputId, value);
    };

    const handleKeyPress = React.useCallback(
        (event) => {
            const validTypes = ['text', 'tel', 'password'];
            const targetId = event.target?.id;
            const targetType = event.target?.type;
            if (!inputsRefs?.current[targetId] || !validTypes.some((type) => type === targetType)) return;
            if (event.keyCode === 13 && !loading) {
                handleSubmitClick();
            }
        },
        [handleSubmitClick, loading]
    );

    React.useEffect(() => {
        document.addEventListener('keyup', handleKeyPress);
        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [handleKeyPress]);

    const handleOnChange = (name: string, value: any, type?: FormComponents) => {
        const currentInput = inputs.find((input) => input.id === name);
        type = currentInput?.type;

        if (inputsRefs.current[name]) {
            inputsRefs.current[name].value = value;
        }

        let formattedValue = value;

        switch (type) {
            case FormComponents.datepicker:
                formattedValue = value ? moment.parseZone(value).toISOString(true) : null;
                setDataValues((prev) => ({ ...prev, [name]: formattedValue }));
                break;
            case FormComponents.googleLocationAutocomplete:
                setDataValues((prev) => ({ ...prev, [name]: formattedValue }));
                break;
        }

        const inputId = name;
        let allValues = getAllValues();
        allValues[name] = formattedValue;
        const newFormErrorsObj = { ...formErrors };

        const validationInputsValues = {
            [inputId]: allValues[inputId],
        };

        const validationInputsSchema = {};
        if (validationsScheme?.[inputId]) {
            validationInputsSchema[inputId] = validationsScheme[inputId];
        }

        if (currentInput?.group) {
            for (const input of inputs) {
                if (input?.group === currentInput.group && newFormErrorsObj[input.id]) {
                    validationInputsValues[input.id] = allValues[input.id];
                    if (validationsScheme?.[input.id]) {
                        validationInputsSchema[input.id] = validationsScheme[input.id];
                    }
                }
            }
        }

        if (validationInputsSchema) {
            const schema = Yup.object().shape(validationInputsSchema);
            schema
                .validate(validationInputsValues, { abortEarly: false })
                .then((v) => {
                    const newErrorsObj = Object.keys(validationInputsSchema).reduce(
                        (prev, inputId) => {
                            return {
                                ...prev,
                                [inputId]: {
                                    error: false,
                                    error_label: '',
                                    touched: formErrors[inputId]?.touched,
                                },
                            };
                        },
                        { ...formErrors }
                    );

                    setFormErrors(newErrorsObj);
                    onErrors && onErrors(null);
                })
                .catch((err) => {
                    try {
                        const newErrorsObj = Object.keys(validationInputsSchema).reduce(
                            (prev, inputId) => {
                                const error = err.inner.find((singleSchema) => {
                                    return singleSchema.path === inputId;
                                });
                                return {
                                    ...prev,
                                    [inputId]: {
                                        error: error ? true : false,
                                        error_label: error ? error.message : '',
                                        touched: error ? true : formErrors[inputId].touched,
                                    },
                                };
                            },
                            { ...formErrors }
                        );

                        setFormErrors(newErrorsObj);
                        onErrors && onErrors(newErrorsObj);
                    } catch (error) {}
                });
        }

        onFieldChange && onFieldChange(name, formattedValue);
    };

    const PasswordErrorComponent = (error: boolean, text: string) => {
        return (
            <div className={classes.passwordError}>
                <div className={classes.passwordIcons}>
                    <Icon name={error ? IconsType.red_close_piqk : IconsType.green_done_piqk}></Icon>
                </div>
                <Typography variant="tSmallRegular">{t(text)}</Typography>
            </div>
        );
    };

    const renderInput = (input: FormInput) => {
        const {
            id,
            type,
            labelT,
            labelTrans,
            placeholderT,
            selectProps,
            textfieldProps,
            checkboxProps,
            switchProps,
            datepickerProps,
            googleLocationAutocompleteProps,
            typographyProps,
            typographyTextT,
            radioButtonsProps,
            buttonRadioGroupProps,
            disableOptionsT,
            googleLocationAutocompleteDefaultObject,
            linkProps,
        } = input;

        const defaultProps: any = {
            name: id,
            id: id,
            label: labelT && (typeof labelT === 'string' ? t(labelT) : t(labelT.t, labelT.values)),
            placeholder:
                placeholderT &&
                (typeof placeholderT === 'string' ? t(placeholderT) : t(placeholderT.t, placeholderT.values)),
            error: formErrors[id]?.error || error,
            helperText: formErrors[id]?.error_label,
            variant: variant,
            color: color,
            touched: formErrors[id]?.touched ? 1 : 0,
            inputRef: (el) => (inputsRefs.current[id] = el),
        };

        if (labelTrans) {
            defaultProps.label = <Trans {...labelTrans} />;
        }

        switch (type) {
            case FormComponents.textfield:
                if (validationsScheme) {
                    const isPasswordValidation = validationsScheme[defaultProps.id]?.tests?.some(
                        (test) => test?.OPTIONS?.name === 'password'
                    );
                    if (isPasswordValidation && defaultProps.error) {
                        const passwordError: PasswordErrors[] = defaultProps.helperText?.split(',');

                        defaultProps.helperText = (
                            <>
                                {PasswordErrorComponent(
                                    passwordError?.includes(PasswordErrors.LEN),
                                    config.featuresFlags['password32Characters']
                                        ? t('register.min_char32')
                                        : t('register.min_char')
                                )}
                                {PasswordErrorComponent(
                                    passwordError?.includes(PasswordErrors.DIGIT),
                                    t('register.min_number')
                                )}
                                {PasswordErrorComponent(
                                    passwordError?.includes(PasswordErrors.ULC),
                                    t('register.least_low_upper_case')
                                )}
                                {config.featuresFlags['passwordWithSpecialCharacters'] &&
                                    PasswordErrorComponent(
                                        passwordError?.includes(PasswordErrors.SYMBOL),
                                        t('register.at_least_one_symbol')
                                    )}
                                {/*  */}
                            </>
                        );
                    }
                    if (isPasswordValidation && !defaultProps.error && defaultProps.touched) {
                        defaultProps.helperText = <>{PasswordErrorComponent(false, t('register.strongPassword'))}</>;
                    }
                }

                return (
                    <TextField
                        onBlur={(e) => {
                            onBlurTextField && handleOnBlur(e);
                        }}
                        helperText
                        {...defaultProps}
                        {...textfieldProps}
                        disabled={textfieldProps?.disabled || loading || disabled}
                        onChange={(e) => handleOnChange(id, e.target.value)}
                    />
                );
            case FormComponents.select:
                const { options } = selectProps || {};
                if (!options) return null;
                return (
                    <Select
                        {...defaultProps}
                        {...selectProps}
                        disabled={selectProps?.disabled || loading || disabled}
                        options={options?.map((option) => ({
                            value: option.value,
                            label: disableOptionsT ? option.label : t(option.label.toString()).toString(),
                            selectedLabel:
                                option.selectedLabel && typeof option.selectedLabel === 'string'
                                    ? t(option.selectedLabel)
                                    : option.selectedLabel,
                        }))}
                        onChange={(v) => handleOnChange(id, v)}
                    />
                );
            case FormComponents.checkbox:
                return (
                    <Checkbox
                        {...defaultProps}
                        {...checkboxProps}
                        disabled={checkboxProps?.disabled || loading || disabled}
                        onChange={(c) => handleOnChange(id, c, type)}
                    />
                );
            case FormComponents.switch:
                return (
                    <Switch
                        {...defaultProps}
                        {...switchProps}
                        label
                        disabled={switchProps?.disabled || loading || disabled}
                        onChange={(e, c) => handleOnChange(id, c)}
                    />
                );
            case FormComponents.datepicker:
                return (
                    <DatePicker
                        {...defaultProps}
                        {...datepickerProps}
                        disabled={datepickerProps?.disabled || loading || disabled}
                        onChange={(date) => handleOnChange(id, date, type)}
                    />
                );
            case FormComponents.googleLocationAutocomplete:
                return (
                    <GoogleLocationAutocomplete
                        {...defaultProps}
                        {...googleLocationAutocompleteProps}
                        disabled={googleLocationAutocompleteProps?.disabled || loading || disabled}
                        countriesList={googleLocationAutocompleteProps?.countriesList || []}
                        defaultValueObject={googleLocationAutocompleteDefaultObject}
                        onChange={(obj) => handleOnChange(id, obj, type)}
                    />
                );
            case FormComponents.typography:
                return (
                    <Typography {...typographyProps}>
                        {typographyTextT &&
                            (typeof typographyTextT === 'string'
                                ? t(typographyTextT)
                                : t(typographyTextT.t, typographyTextT.values))}
                    </Typography>
                );
            case FormComponents.link:
                if (!linkProps) return;
                return (
                    <Link to={linkProps.to}>
                        <Typography {...linkProps?.typographyProps}>{t(linkProps?.text || '')}</Typography>
                    </Link>
                );
            // case FormComponents.phoneNumber:
            //     return (
            //         <Typography {...typographyProps}>
            //             {typographyTextT &&
            //                 (typeof typographyTextT === 'string'
            //                     ? t(typographyTextT)
            //                     : t(typographyTextT.t, typographyTextT.values))}
            //         </Typography>
            //     );
            case FormComponents.radioButtons:
                return (
                    <RadioButtons
                        {...defaultProps}
                        {...radioButtonsProps}
                        disabled={radioButtonsProps?.disabled || loading || disabled}
                        options={(radioButtonsProps?.options || []).map((option) => ({
                            ...option,
                            label: disableOptionsT ? option.label : t(option.label.toString()).toString(),
                            helperText: option.helperText && t(option.helperText.toString()).toString(),
                        }))}
                        onChange={(v) => handleOnChange(id, v, type)}
                    />
                );
            case FormComponents.buttonRadioGroup:
                return (
                    <ButtonRadioGroup
                        {...defaultProps}
                        {...buttonRadioGroupProps}
                        disabled={buttonRadioGroupProps?.disabled || loading || disabled}
                        helperText={defaultProps.helperText || buttonRadioGroupProps?.helperText || ''}
                        options={(buttonRadioGroupProps?.options || []).map((option) => ({
                            ...option,
                            label: disableOptionsT ? option.label : t(option.label.toString()).toString(),
                        }))}
                        onChange={(v) => handleOnChange(id, v, type)}
                    />
                );
        }
    };

    // const isMaxWidthItem = (input: FormInput) => {
    //     const maxWidthTypes = [
    //         FormComponents.textfield,
    //         FormComponents.select,
    //         FormComponents.datepicker,
    //         FormComponents.googleLocationAutocomplete,
    //     ];
    //     return maxWidthTypes.some((item) => item === input.type);
    // };

    return (
        <FormControl style={customWrapperCss} classes={{ root: classes.form_wrapper }} color={color} error={error}>
            <Grid
                container
                classes={{
                    root: classNames({
                        [classes.spacing6]: true,
                        [classes.formRow]: fullWidth ? false : true,
                        [classes.with_max_width]: isMaxWidthForm,
                    }),
                }}
            >
                <Grid item xs={12}>
                    <Grid container classes={{ root: classes.spacing6 }}>
                        {inputs
                            .filter((input) => !input.hidden)
                            .map((input, index) => (
                                <Grid
                                    classes={{
                                        item: classNames(
                                            {
                                                [classes.form_item]: true,
                                            },
                                            input.formInputClasses
                                        ),
                                    }}
                                    style={{ order: input.order || index + 1 }}
                                    item
                                    xs={12}
                                    {...input.breakpointsSizes}
                                    key={input.id}
                                >
                                    {renderInput(input)}
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                {((onSubmitClick && submitBtnText) || (onCancelClick && cancelBtnT)) && (
                    <Grid
                        item
                        xs={12}
                        classes={{
                            root: submitBtnCenter ? '100%' : classes.with_max_width,
                        }}
                    >
                        <Grid
                            container
                            classes={{
                                root: classNames(classes.buttonsSection, classes.spacing6, {
                                    [classes.reverseSubmitCancelButtons]: reverseSubmitCancelButtons,
                                }),
                            }}
                            justify={submitBtnCenter ? 'center' : undefined}
                        >
                            {onCancelClick && cancelBtnT && (
                                <Grid item xs={'auto'} {...(cancelBtnBreakPoints || {})}>
                                    <Button
                                        variant="contained"
                                        color={'default'}
                                        onClick={onCancelClick}
                                        disabled={cancelBtnProps?.disabled || loading}
                                        {...cancelBtnProps}
                                    >
                                        {t(cancelBtnT)}
                                    </Button>
                                </Grid>
                            )}
                            {onSubmitClick && submitBtnText && (
                                <Grid
                                    item
                                    xs={onCancelClick && cancelBtnT ? 'auto' : submitBtnCenter ? 8 : 12}
                                    {...(submitBtnBreakPoints || {})}
                                >
                                    <Button
                                        variant="contained"
                                        color={color}
                                        onClick={handleSubmitClick}
                                        fullWidth={onCancelClick && cancelBtnT ? false : true}
                                        loading={loading}
                                        {...btnProps}
                                        // disabled={Object.keys(formErrors).length === 0 ? !touchedTextField : true}
                                    >
                                        {t(submitBtnText)}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </FormControl>
    );
});

export default FormGenerator;
