import { getTokenFromLocalStorage, saveTokenToLocalStorage } from 'helpers/token';
import { EUserTypes } from './actionsTypes';
import { batch } from 'react-redux';
import axios from 'services/axios';
import { system_stopLoader } from 'store/system/actions';
import { auth_logout } from 'store/auth/actions';
import {
    AccountInfoParsed,
    TradingPlatformAccountInfo,
    VerifyPhoneDto,
    CreateKycFragmentsRequest,
    UpdateKycAcceptRiskRequest,
    PushSubscriptionSchemaKeys,
} from 'services/generatedClientFromSwagger/models';
import { ContactUsRequest, CreateTPAccountRequest } from 'services/generatedClientFromSwagger/models';
import { RootState } from '..';
import { user_promoCode, user_userInfo } from './selectors';
import { getLastSelectedTpFromLocalStorage, saveLastSelectedTp } from 'helpers/lastSelectedTP';
import mixPanel, { mixPanelEventsTypes } from 'helpers/mixPanel';
import { platform_resetState } from 'store/platform/actions';
import queryString from 'query-string';
import { EAuthTypes } from 'store/auth/actionsTypes';
import { parseToken } from 'helpers/jwtParser';
import i18 from 'i18n';
import Smartico from 'services/smartico';
import LuckyOrange from 'services/LuckyOrange';
import config, { FeatureFlag } from 'config/common';
import socketConnection from '../../services/socket-io/connection';
import KrakenWebsocketConnection from 'services/krakenSocket/krakenSocket';
import site24x7 from 'services/site24x7';
import { notification_markNotificationWebPush } from '../../features/Notifications/store/actions';
import LogRocket from 'logrocket';
import { prop__setMainTpAccount } from 'features/prop/store/plans/actions';

export interface LoginQueryParams {
    ssotoken?: string;
    wttoken?: string;
    token?: string;
    accountid?: string;
    crmtoken?: string;
    webpushtoken?: string;
}

export const user_startLoader = () => ({
    type: EUserTypes.START_LOADER,
});

export const user_stopLoader = () => ({
    type: EUserTypes.STOP_LOADER,
});

export const user_getUserData = () => {
    return async (dispatch) => {
        try {
            const initDefaultParams: LoginQueryParams = queryString.parse(window.location.search);
            const defaultParams = Object.keys(initDefaultParams).reduce<LoginQueryParams>((acc, key) => {
                return {
                    ...acc,
                    [key.toLowerCase()]: initDefaultParams[key],
                };
            }, {});
            try {
                if (defaultParams?.webpushtoken) {
                    dispatch(notification_markNotificationWebPush(defaultParams.webpushtoken));
                }
                if (defaultParams.ssotoken) {
                    saveTokenToLocalStorage(defaultParams.ssotoken);
                    let link = window.location.href.split('?')[0];
                    let params = new URLSearchParams();
                    for (const key in defaultParams) {
                        if (Object.prototype.hasOwnProperty.call(defaultParams, key)) {
                            if (key !== 'ssotoken') {
                                params.append(key, defaultParams[key]);
                            }
                        }
                    }

                    dispatch({ type: EAuthTypes.LOGIN_SUCCESS });
                    window.history.replaceState({}, document.title, `${link}?${params.toString()}`);
                    const parsedToken = parseToken(defaultParams.ssotoken);
                    if (parsedToken) {
                        mixPanel.track(mixPanelEventsTypes.LOGIN, { loginType: 'SSO', status: true });
                    }
                }
                if (defaultParams.wttoken) {
                    const wtSSOLoginRes = await axios.AuthApi.authControllerWtSSOLogin({
                        token: defaultParams.wttoken,
                    });
                    if (wtSSOLoginRes.data?.jwt) {
                        saveTokenToLocalStorage(wtSSOLoginRes.data.jwt);
                        dispatch({ type: EAuthTypes.LOGIN_SUCCESS });
                        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
                        const parsedToken = parseToken(wtSSOLoginRes.data.jwt);
                        if (parsedToken) {
                            mixPanel.track(mixPanelEventsTypes.LOGIN, { loginType: 'WT_SSO', status: true });
                        }
                    }
                }
                if (defaultParams.crmtoken) {
                    const crmSSOLoginRes = await axios.AuthApi.authControllerCrmSSOLogin({
                        token: defaultParams.crmtoken,
                    });
                    if (crmSSOLoginRes.data?.jwt) {
                        saveTokenToLocalStorage(crmSSOLoginRes.data.jwt);
                        dispatch({ type: EAuthTypes.LOGIN_SUCCESS });
                        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
                        const parsedToken = parseToken(crmSSOLoginRes.data.jwt);
                        if (parsedToken) {
                            mixPanel.track(mixPanelEventsTypes.LOGIN, { loginType: 'CRM_SSO', status: true });
                        }
                    }
                }
            } catch (error) {}
            const token = getTokenFromLocalStorage();
            dispatch(user_getUserDataStart());
            if (!token && !defaultParams.ssotoken && !defaultParams.wttoken) {
                return batch(() => {
                    dispatch(system_stopLoader());
                    dispatch(user_getUserDataStartFailed());
                });
            }

            socketConnection.connect();
            if (token) {
                socketConnection.identifyUser(token);
            }

            const userInfoRes = await axios.UserApi.userControllerGetUserData();
            const userInfo = userInfoRes.data?.accountInfo;
            if (userInfo.hasCcsWallet) {
                KrakenWebsocketConnection.init();
            }
            if (!userInfo) throw new Error();
            const lastSelectedTpId = getLastSelectedTpFromLocalStorage();
            let selectedTp;
            if (lastSelectedTpId) {
                selectedTp = userInfo.tradingPlatformAccounts?.find((tp) => tp.id === lastSelectedTpId);
            }
            if (!selectedTp && config.initialTPType) {
                selectedTp = userInfo.tradingPlatformAccounts?.find(
                    (tp) => tp.tradingPlatform?.type?.toLowerCase() === config.initialTPType?.toLowerCase()
                );
            }
            if (!selectedTp) {
                selectedTp = userInfo.tradingPlatformAccounts?.[0];
            }

            batch(() => {
                dispatch(system_stopLoader());
                dispatch(user_getUserDataStartSuccess(userInfo));
                if (config.featuresFlags[FeatureFlag.PROP]) {
                    dispatch(prop__setMainTpAccount(userInfo));
                }

                dispatch(user_changeSelectedTP(selectedTp));
            });

            LogRocket.identify(userInfo.id || '');
            mixPanel.identify(userInfo.id || '');
            mixPanel.setPeople(userInfo);
            if (!defaultParams.ssotoken && !defaultParams.wttoken) {
                mixPanel.track(mixPanelEventsTypes.AUTO_LOGIN, { loginType: 'JWT', status: true });
            }

            Smartico.login(userInfo.id || '');
            site24x7.setUser(userInfo.id || '');
            LuckyOrange.init(userInfo);
        } catch (error) {
            mixPanel.track(mixPanelEventsTypes.LOGIN, { status: false });
            return batch(() => {
                dispatch(auth_logout());
                dispatch(system_stopLoader());
                dispatch(user_getUserDataStartFailed());
            });
        }
    };
};

const user_getUserDataStart = () => ({
    type: EUserTypes.GET_USER_DATA_START,
});

const user_getUserDataStartSuccess = (payload: AccountInfoParsed) => ({
    type: EUserTypes.GET_USER_DATA_SUCCESS,
    payload,
});

const user_getUserDataStartFailed = () => ({
    type: EUserTypes.GET_USER_DATA_FAILED,
});

export const user_UpdateUserInfo = (payload: AccountInfoParsed, sumScore = false) => {
    return async (dispatch, getState) => {
        dispatch({ type: EUserTypes.UPDATE_USER_INFO_START });
        try {
            const state: RootState = getState();
            const userInfo = user_userInfo(state);
            const objectToUpdate = {
                ...userInfo,
                ...payload,
            };
            Object.keys(objectToUpdate).forEach((itemKey) => {
                if (
                    (!objectToUpdate[itemKey] && typeof objectToUpdate[itemKey] !== 'boolean') ||
                    (typeof objectToUpdate[itemKey] === 'object' && !Array.isArray(objectToUpdate[itemKey]))
                ) {
                    delete objectToUpdate[itemKey];
                }
            });
            let updateRes;
            if (sumScore) {
                updateRes = await axios.UserApi.userControllerCompleteYourProfileDone(objectToUpdate);
            } else {
                updateRes = await axios.UserApi.userControllerUpdateAccountInfo({
                    ...objectToUpdate,
                    custom_field_citizenship: payload.custom_field_citizenship,
                });
            }
            const newUserInfo = updateRes.data;

            dispatch({ type: EUserTypes.UPDATE_USER_INFO_SUCCESS, payload: newUserInfo });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.UPDATE_USER_INFO_FAILED });
            return false;
        }
    };
};

export const user_contactUs = (payload: ContactUsRequest) => {
    return async () => {
        try {
            const userInfoRes = await axios.UserApi.userControllerContactUs(payload);
            return Boolean(userInfoRes.data?.status);
        } catch (error) {
            return false;
        }
    };
};

export const user_createTPAccount = (payload: CreateTPAccountRequest) => {
    return async (dispatch, getState) => {
        dispatch({ type: EUserTypes.CREATE_TP_ACCOUNT_START });
        try {
            const state: RootState = getState();
            const promoCode = user_promoCode(state);
            const res = await axios.TPApi.tPControllerCreateTPAccount({ ...payload, promoCode });
            const newAccountInfo = res?.data?.accountInfo;
            dispatch({ type: EUserTypes.CREATE_TP_ACCOUNT_SUCCESS, payload: newAccountInfo });
            mixPanel.track(mixPanelEventsTypes.CREATE_NEW_TP, payload);
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.CREATE_TP_ACCOUNT_FAILED });
            return false;
        }
    };
};

export const user_changeSelectedTP = (payload: TradingPlatformAccountInfo | undefined) => {
    return async (dispatch) => {
        batch(() => {
            dispatch(platform_resetState());
            dispatch({ type: EUserTypes.CHANGE_SELECTED_TP, payload });
        });
        saveLastSelectedTp(payload?.id);
        mixPanel.track(mixPanelEventsTypes.CHANGE_SELECTED_TP, {
            id: payload?.id,
            currency: payload?.baseCurrency?.code,
            type: payload?.tradingPlatform?.type,
        });
    };
};

export const user_refreshUserData = () => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.REFRESH_USER_DATA_START });
        try {
            const res = await axios.UserApi.userControllerRefreshUser({ language: i18.language });
            dispatch({ type: EUserTypes.REFRESH_USER_DATA_SUCCESS, payload: res.data });
        } catch (error) {
            dispatch({ type: EUserTypes.REFRESH_USER_DATA_FAILED });
        }
    };
};

export const user_getReachedCreditVolume = (tpAccountId: string) => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.IS_REACHED_CREDIT_VOLUME_START });
        try {
            const res = await axios.TPApi.tPControllerIsReachedCreditVolume({
                tpAccountId,
            });
            dispatch({ type: EUserTypes.IS_REACHED_CREDIT_VOLUME_SUCCESS, payload: res.data });
        } catch (error) {
            dispatch({ type: EUserTypes.IS_REACHED_CREDIT_VOLUME_FAILED });
        }
    };
};

export const user_sendEmailVerificationToAccount = () => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_START });
        try {
            const res = await axios.VerifyApi.verifyControllerSendVerificationToAccount({ verifyEmailAddress: true });
            if (res?.status) {
                dispatch({ type: EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_SUCCESS });
            } else {
                dispatch({ type: EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_FAILED });
            }
        } catch (error) {
            dispatch({ type: EUserTypes.SEND_EMAIL_VERIFICATION_TO_ACCOUNT_FAILED });
        }
    };
};

export const user_sendPhoneVerificationToAccount = () => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_START });
        try {
            const res = await axios.VerifyApi.verifyControllerSendVerificationToAccount({ verifyPhoneNumber: true });
            if (res?.status) {
                dispatch({ type: EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_SUCCESS });
                return true;
            } else {
                dispatch({ type: EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_FAILED });
                return false;
            }
        } catch (error) {
            dispatch({ type: EUserTypes.SEND_PHONE_VERIFICATION_TO_ACCOUNT_FAILED });
            return false;
        }
    };
};

export const user_verifyPhone = (payload: VerifyPhoneDto) => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.VERIFY_PHONE_START });
        try {
            const res = await axios.VerifyApi.verifyControllerVerifyPhone({ phoneVerifyCode: payload.phoneVerifyCode });
            if (res?.data?.status && res.data?.jwt) {
                const jwt = res.data.jwt;
                saveTokenToLocalStorage(jwt);
                dispatch({ type: EUserTypes.VERIFY_PHONE_SUCCESS });
                return true;
            } else {
                dispatch({ type: EUserTypes.VERIFY_PHONE_FAILED });
                return false;
            }
        } catch (error) {
            dispatch({ type: EUserTypes.VERIFY_PHONE_FAILED });
        }
    };
};

export const auth_verifyEmail = (emailVerifyCode: string, accountId: string) => {
    return async (dispatch) => {
        dispatch({
            type: EUserTypes.VERIFY_EMAIL_START,
        });
        try {
            const res = await axios.VerifyApi.verifyControllerVerifyEmail({
                emailVerifyCode: emailVerifyCode,
                accountId: accountId,
            });

            if (res.data.jwt) {
                saveTokenToLocalStorage(res.data.jwt);
            }
            dispatch({
                type: EUserTypes.VERIFY_EMAIL_FINISHED,
            });
            return { status: res.data.status, hasJwt: res.data.jwt !== null };
        } catch (error) {
            dispatch({
                type: EUserTypes.VERIFY_EMAIL_FAILED,
            });
            return { status: false, hasJwt: false };
        }
    };
};

export const user_createKycFragment = (payload: CreateKycFragmentsRequest) => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_DATA_START });
        try {
            const res = await axios.KycApi.kycApiControllerCreateKycFragments(payload);
            dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_DATA_SUCCESS, payload: res.data });
            return true;
        } catch (e) {
            dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_DATA_FAILED });
        }
    };
};

export const user_updateKycAcceptRisk = (payload: UpdateKycAcceptRiskRequest) => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_START });
        try {
            const res = await axios.KycApi.kycApiControllerUpdateKycAcceptRisk(payload);
            dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_SUCCESS, payload: res.data });
            return true;
        } catch (e) {
            dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_FAILED });
        }
    };
};

export const user_getUserPushNotificationPermission = () => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const permissionStatus = state.user.permissionStatus;
        dispatch({ type: EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_START });
        try {
            const permission = await Notification.requestPermission();

            if (permission === 'granted') {
                if ('serviceWorker' in navigator) {
                    dispatch(user_registerToPushNotifications());
                }
            }

            if (permission === 'denied') {
                if ('serviceWorker' in navigator) {
                    dispatch(user_registerToPushNotificationsDenied());
                }
            }

            dispatch({
                type: EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_SUCCESS,
                payload: permission,
            });
            mixPanel.track(mixPanelEventsTypes.PUSH_NOTIFICATION_PERMISSION_STATUS, permissionStatus);
        } catch (error) {
            dispatch({ type: EUserTypes.UPDATE_ACCOUNT_PUSH_NOTIFICATION_PERMISSION_FAILED });
        }
    };
};

export const user_registerToPushNotifications = () => {
    return async (dispatch) => {
        try {
            const register = await navigator.serviceWorker.register(
                `${import.meta.env.PUBLIC_URL}/notificationsServiceWorker.js`,
                {
                    scope: '/',
                }
            );

            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 3000);
            });

            const subscription = (
                await register.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        'BLPO05C8WXSTh_UMRHiAz2_CcA8Deic3qlc9weXre8aMSK3yxGnzG3V8HPrGFoLjB-dxuRmEm1B0cawABo7IJVM',
                })
            ).toJSON();

            const { endpoint, keys } = subscription;

            await axios.NotificationsApi.notificationsApiControllerCreateSusbscription({
                endpoint: endpoint || '',
                keys: keys as PushSubscriptionSchemaKeys,
            });

            // dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_SUCCESS, payload: res.data });
            return true;
        } catch (e) {
            console.log(e);

            // dispatch({ type: EUserTypes.UPDATE_ACCOUNT_KYC_ACCEPT_RISK_FAILED });
        }
    };
};

export const user_registerToPushNotificationsDenied = () => {
    return async (dispatch) => {
        console.log(`${import.meta.env.PUBLIC_URL}/notificationsServiceWorker.js`);

        try {
            await navigator.serviceWorker.register(`${import.meta.env.PUBLIC_URL}/notificationsServiceWorker.js`, {
                scope: '/',
            });

            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 3000);
            });

            await axios.UserApi.userControllerUpdateNotificationSettings({
                disabledNotifications: true,
            });

            return true;
        } catch (e) {
            console.log(e);
        }
    };
};

export const user_getReferralCode = (accountId: string) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EUserTypes.GET_REFERRAL_CODE_START });
            const res = await axios.UserApi.userControllerGetReferralCode(accountId);
            const data = res.data.referralCode;
            dispatch({ type: EUserTypes.GET_REFERRAL_CODE_SUCCESS, payload: data });
        } catch (error) {
            dispatch({ type: EUserTypes.GET_REFERRAL_CODE_FAILED });
        }
    };
};

export const user_changeSelectedTpByName = (tpName: string) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const userInfo = user_userInfo(state);

        const selectedTp = userInfo.tradingPlatformAccounts?.find(
            (tp) => tp.name?.toLowerCase() === tpName.toLowerCase()
        );
        dispatch(user_changeSelectedTP(selectedTp));
    };
};

export const user_updateStoreUser = (payload: AccountInfoParsed) => {
    return async (dispatch, getState) => {
        const state: RootState = getState();
        const userInfo = user_userInfo(state);
        try {
            const newUser = { ...userInfo, ...payload };
            dispatch({ type: EUserTypes.UPDATE_USER_INFO_SUCCESS, payload: newUser });
            return true;
        } catch (error) {
            dispatch({ type: EUserTypes.UPDATE_USER_INFO_FAILED });
            return false;
        }
    };
};

export const user_updateUserAddressReset = () => {
    return async (dispatch) => {
        dispatch({ type: EUserTypes.UPDATE_USER_INFO_RESET });
    };
};
