import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isMobile, isRtl, isTablet } from 'helpers/device_helper';
import { logoDarkMode, logoLightMode } from 'config/dynamicAssets';
import config from 'config/common';

export interface LogoProps {
    fromMenu?: boolean;
    alwaysShow?: boolean;
    id?: string;
    width?: string;
    bigLogo?: boolean;
    isPrivate?: boolean;
}
interface LogoStyleProps {
    width?: string;
    bigLogo?: boolean;
    fromMenu?: boolean;
    alwaysShow?: boolean;
    isPrivate?: boolean;
}
const useStyles = makeStyles((theme) => ({
    login_link: (props: LogoStyleProps) => ({
        display: props.fromMenu ? 'block' : 'none',
        ...isTablet({
            display: props.fromMenu ? 'none' : 'block',
        }),
        ...(props.alwaysShow && {
            display: 'block',
        }),
    }),
    logo_img: (props: LogoStyleProps) => ({
        display: 'block',
        backgroundImage: `url(${theme.palette.type === 'dark' ? logoDarkMode : logoLightMode})`,
        backgroundPosition: isRtl() ? 'right center' : 'left center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width:
            !props.isPrivate && config?.logoSettings?.width
                ? `${config?.logoSettings?.width}px`
                : props.width
                  ? props.width
                  : '12.0rem',

        height:
            !props.isPrivate && config?.logoSettings?.height
                ? `${config?.logoSettings?.height}px`
                : props.bigLogo
                  ? '8.2rem'
                  : '3.6rem',
        ...isMobile({
            width: props.width ? props.width : '12.0rem',
            height: props.bigLogo ? '8.2rem' : '3.6rem',
        }),
        cursor: 'pointer',
    }),
}));

const Logo: React.FunctionComponent<LogoProps> = ({ fromMenu, alwaysShow, id, width, bigLogo, isPrivate }) => {
    const classes = useStyles({ fromMenu, alwaysShow, width, bigLogo, isPrivate });
    const renderItem = useMemo(
        () =>
            !config.logoRedirectUrl
                ? (children) => (
                      <Link to="/" replace className={classes.login_link}>
                          {children}
                      </Link>
                  )
                : (children) => (
                      <a href={config.logoRedirectUrl} className={classes.login_link}>
                          {children}
                      </a>
                  ),
        [classes.login_link]
    );
    return <>{renderItem(<span className={classes.logo_img} id={id} />)}</>;
};

export default Logo;
