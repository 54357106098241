import React from 'react';
import { Hidden, makeStyles } from '@material-ui/core';
import { isRtl, isTablet, isMobile } from 'helpers/device_helper';
import BackOrMenuBtn from 'components/BackOrMenuBtn';
// import MainNotificationBtn from 'features/Notifications/components/MainNotificationBtn';
import Logo from 'components/Header/components/Logo';
import Icon, { IconsType } from 'components/Icon';
import Typography from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ConditionalWrapper from 'components/ConditionalWrapper';
import config from 'config/common';
import LanguageSelect from './LanguageSelect';
import { KycStatusChip } from 'features/prop/components/KycStatusChip/KycStatusChip.render';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';
import { useSelector } from 'react-redux';
import { RootState } from 'store/index';
const kycActive = config.featuresFlags['kyc'];

export enum UserDepositStatus {
    'KYC' = 'complete_kyc',
    'VERIFIED' = 'verified',
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: `0 ${theme.spacing(3)}rem`,
        width: '100%',
        ...isMobile({
            alignItems: 'start',
        }),
    },
    section_one: {
        color: theme.palette.grayVariants[500],
    },
    section_two: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        ...isMobile({
            alignSelf: 'flex-start',
            flexFlow: 'column',
        }),
    },
    wrapper__nonVerified: {
        backgroundColor: '#FEF0C7',
        color: '#DC6803',
        width: 'fit-content',
        height: '26px',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper__verified: {
        backgroundColor: '#D3F8DF',
        color: '#099250',
    },
    icon: {
        margin: '0px 5.33px',
        stroke: '#DC6803',
    },
    icon__verified: {
        stroke: '#099250',
    },
    statusButton: { paddingRight: '5.33px', ...isRtl({ paddingLeft: '5.33px' }) },
    chip_mobile: () => ({
        ...isMobile({
            display: 'flex',
            justifyContent: 'flex-end',
        }),
    }),
}));

export interface MenuTopSectionProps {
    notificationsAmount: number;
    menuState: boolean;
    toggleMenuState: () => void;
    userFlowGuard: { possibleLinks: Array<string>; link: string; buttonType: string; icon: IconsType };
    displayBadge?: boolean;
}

const MenuTopSection = ({ menuState, toggleMenuState, userFlowGuard, displayBadge = false }: MenuTopSectionProps) => {
    const classes = useStyles({ menuState });
    const userInfo = useSelector((store: RootState) => store.user.userInfo) as AccountInfoParsed;
    const { t } = useTranslation('dashboard');

    //for now langue in mobile will work only when kyc is OFF **!temporary!**

    const displayKyc = userFlowGuard && kycActive;

    return (
        <div className={classes.wrapper}>
            <div className={classes.section_one}>
                {isTablet() && <BackOrMenuBtn toggleSideMenu={toggleMenuState} />}
                <Logo fromMenu={true} />
            </div>
            {displayKyc && menuState && (
                <ConditionalWrapper
                    condition={userFlowGuard.buttonType !== UserDepositStatus.VERIFIED}
                    wrapper={(wrapperChildren) => (
                        <Link to={`${userFlowGuard.link}`} onClick={() => toggleMenuState()}>
                            {wrapperChildren}
                        </Link>
                    )}
                >
                    <div
                        className={classNames({
                            [classes.wrapper__nonVerified]: true,
                            [classes.wrapper__verified]: userFlowGuard.buttonType === UserDepositStatus.VERIFIED,
                        })}
                    >
                        <Icon
                            className={classNames({
                                [classes.icon]: true,
                                [classes.icon__verified]: userFlowGuard.buttonType === UserDepositStatus.VERIFIED,
                            })}
                            name={userFlowGuard.icon}
                        />
                        <Typography className={classes.statusButton} variant="tXsMedium">
                            {t(userFlowGuard.buttonType)}
                        </Typography>
                    </div>
                </ConditionalWrapper>
            )}
            <div className={classes.section_two}>
                {!displayKyc && menuState && (
                    <Hidden smUp>
                        <LanguageSelect menuState={menuState} />
                    </Hidden>
                )}
                {displayBadge && (
                    <div className={classes.chip_mobile}>
                        <KycStatusChip status={userInfo?.kycVerificationStatus} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuTopSection;
