import { prop_myPlans_getSelectedChallenge } from 'features/prop/store/plans/selectors';
import { PropChallenge, PickListInfo, RetryParams } from 'services/generatedClientFromSwagger';
import { DeviceType } from 'hooks/useDeviceType';
import { system_toggleSideMenu } from 'store/system/actions';
import { system_selectorDeviceTypeSize, system_side_menu_state } from 'store/system/selectors';
import { user_accountType, user_fullName } from 'store/user/selectors';

export interface PrivateHeaderProps {
    system_toggleSideMenu: () => void;
    user_accountType: PickListInfo | undefined;
    action_networkConnectionState: (status: boolean) => void;
    networkConnectionStateSelector: boolean;
    user_fullName: string;
    system_selectorDeviceTypeSize: DeviceType | '';
    challenges: PropChallenge[];
    selectedChallenge: PropChallenge;
    isRetryAvailableTrue: RetryParams;
    menuState: boolean;
}

export const mapStateToProps = (state) => ({
    user_accountType: user_accountType(state),
    user_fullName: user_fullName(state),
    system_selectorDeviceTypeSize: system_selectorDeviceTypeSize(state),
    selectedChallenge: prop_myPlans_getSelectedChallenge(state),
    menuState: system_side_menu_state(state),
});

export const mapDispatchToProps = {
    system_toggleSideMenu,
};
