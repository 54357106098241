import { RootState } from 'store';
import { ESUMSUBTypes } from './actionsTypes';
import axios from 'services/axios';
import { batch } from 'react-redux';

export const sumsub_getSumsubData = () => {
    return async (dispatch, getState) => {
        try {
            const state: RootState = getState();
            const owningBusinessUnit = state.user?.userInfo?.owningBusinessUnit;
            if (!owningBusinessUnit) throw new Error('');
            dispatch({ type: ESUMSUBTypes.GET_SUMSUB_DATA });
            const subsubDataRes = await axios.SumsubApi.sumsubControllerGetAccessTokenAndFlow({
                sourceKey: owningBusinessUnit,
            });
            dispatch({ type: ESUMSUBTypes.GET_SUMSUB_DATA_SUCCESS, payload: subsubDataRes.data });
            return subsubDataRes.data.accessToken;
        } catch (error) {
            return batch(() => {
                dispatch({ type: ESUMSUBTypes.GET_SUMSUB_DATA_FAILED });
            });
        }
    };
};

export const sumsub_requestApproved = () => ({
    type: ESUMSUBTypes.REQUEST_APPROVED,
});
