/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PropChallenge
 */
export class PropChallenge {
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    ownerId?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    owningBusinessUnit?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    propChallengeId?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    account?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    packageName?: string;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    funds?: number;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    tceChallengeID?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    token?: string;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    targetPrecentage?: number;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    maxDailyLossPrecentage?: number;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    shutDownPrecentage?: number;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    profitPercent?: number;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    minTradingDays?: number;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    durationInDays?: number;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    challengeStatus?: PropChallengeChallengeStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    internalStatus?: PropChallengeInternalStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    internalStatusReason?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    tpAccount?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    tpName?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    purchaseDate?: string;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    fee?: number;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    promoCode?: string;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    retryDiscountPercent?: number;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    challengeType?: PropChallengeChallengeTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    goalId?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    clientZoneChallengeId?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    packageId?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    relatedChallengeId?: string;
    /**
     *
     * @type {boolean}
     * @memberof PropChallenge
     */
    acceptTermsAndConditions?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof PropChallenge
     */
    autoApprove?: boolean;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    followingChallenges?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    plan?: string;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    leverage?: number;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    tenant?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    endTime?: string;
    /**
     *
     * @type {string}
     * @memberof PropChallenge
     */
    executingPlatform?: PropChallengeExecutingPlatformEnum;
    /**
     *
     * @type {number}
     * @memberof PropChallenge
     */
    weekendTradingMode?: number;
    /**
     *
     * @type {boolean}
     * @memberof PropChallenge
     */
    holdOverWeekend?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum PropChallengeChallengeStatusEnum {
    NotStarted = 'NotStarted',
    Active = 'Active',
    HitEquityTarget = 'HitEquityTarget',
    Draft = 'Draft',
    ManuallyDisqualified = 'ManuallyDisqualified',
    HitTerminationEquityLevel = 'HitTerminationEquityLevel',
    HitMaxDailyLossPercent = 'HitMaxDailyLossPercent',
    HitMaxFloatingCashLoss = 'HitMaxFloatingCashLoss',
    HitMaxFloatingLossPercent = 'HitMaxFloatingLossPercent',
    HitMaxDrawdownPercent = 'HitMaxDrawdownPercent',
    ReachedEndTime = 'ReachedEndTime',
    SpecialCaseEndTime = 'SpecialCaseEndTime',
    LackOfTradingActivity = 'LackOfTradingActivity',
    OpenPendingOrderAtDayEnd = 'OpenPendingOrderAtDayEnd',
    OpenPendingOrderOverWeekend = 'OpenPendingOrderOverWeekend',
    EquityChangeBeforeStart = 'EquityChangeBeforeStart',
    OppositeDirectionTradeFlag128 = 'OppositeDirectionTradeFlag128',
    ChallengeTerminatedInCompetition = 'ChallengeTerminatedInCompetition',
    AccountReceivedAdditionalFunding = 'AccountReceivedAdditionalFunding',
    ChallengeDeleted = 'ChallengeDeleted',
}
/**
 * @export
 * @enum {string}
 */
export enum PropChallengeInternalStatusEnum {
    Success = 'Success',
    Failure = 'Failure',
}
/**
 * @export
 * @enum {string}
 */
export enum PropChallengeChallengeTypeEnum {
    Audition = 'Audition',
    Funded = 'Funded',
    FreeTrial = 'FreeTrial',
}
/**
 * @export
 * @enum {string}
 */
export enum PropChallengeExecutingPlatformEnum {
    SirixEngine = 'SirixEngine',
    TceEngine = 'TCEEngine',
}
