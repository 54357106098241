import { Hidden, Tooltip as TooltipMat } from '@material-ui/core';
import React from 'react';
import useStyles from './tooltip.styles';
import ClickAwayListener from 'components/ClickAwayListener';
import Typography from 'components/Typography';

export interface TooltipProps {
    title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | any;
    reverseColors?: boolean;
    placement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start';
    openMethod?: 'click' | 'hover';
    size?: 'typhographySizeSm' | 'typhographySizeMd' | 'typhographySizeLg';
    display?: 'flex' | 'inline';
    tooltipClass?: string;
    top?: number;
    left?: number;
    disableArrow?: boolean;
    popperClass?: string;
    tooltipRootClass?: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
    const {
        children,
        title,
        placement,
        reverseColors,
        openMethod = 'hover',
        size,
        display,
        tooltipClass,
        top,
        left,
        disableArrow,
        popperClass,
        tooltipRootClass,
    } = props;

    const classes = useStyles({ reverseColors, display, tooltipPosition: { top, left } });

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen((open) => !open);
    };

    return (
        <React.Fragment>
            <Hidden smDown>
                <ClickAwayListener mouseEvent={'onMouseUp'} onClickAway={handleTooltipClose}>
                    <TooltipMat
                        arrow={!disableArrow}
                        interactive
                        className={`${classes.root} ${tooltipRootClass}`}
                        classes={{ tooltip: `${classes.tooltip} ${tooltipClass}`, arrow: classes.arrow }}
                        onClose={handleTooltipClose}
                        title={
                            <Typography className={size && classes[size]} variant={'tXsSemibold'}>
                                {title}
                            </Typography>
                        }
                        placement={placement}
                        open={openMethod === 'click' ? open : undefined}
                    >
                        {openMethod === 'click' ? (
                            <div onClick={handleTooltipOpen}>{children as any}</div>
                        ) : (
                            <div>{children}</div>
                        )}
                    </TooltipMat>
                </ClickAwayListener>
            </Hidden>
            <Hidden mdUp>
                <ClickAwayListener mouseEvent={'onMouseUp'} onClickAway={handleTooltipClose}>
                    <div>
                        <TooltipMat
                            arrow={!disableArrow}
                            interactive
                            className={classes.root}
                            classes={{
                                tooltip: `${classes.tooltip} ${tooltipClass}`,
                                arrow: classes.arrow,
                                popper: popperClass,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <Typography className={size && classes[size]} variant={'tXsSemibold'}>
                                    {title}
                                </Typography>
                            }
                            placement={placement}
                        >
                            <div onClick={handleTooltipOpen}>{children as any}</div>
                        </TooltipMat>
                    </div>
                </ClickAwayListener>
            </Hidden>
        </React.Fragment>
    );
};

export default Tooltip;
