import { FormControl, MenuItem, Select, makeStyles, useTheme } from '@material-ui/core';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { locales } from 'config/i18n';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import Icon, { IconsType } from 'components/Icon';
import classNames from 'classnames';
import Tooltip from 'components/ToolTip';
import ConditionalWrapper from 'components/ConditionalWrapper';
import { LanguageToCountry } from 'helpers/languageToCountry';
import i18n from 'i18n';
import { isMobile, isRtl } from 'helpers/device_helper';
import { newShadows } from 'muiTheme/config/palette';

const flagsGlob = import.meta.glob('/src/assets/flags/*.svg', { eager: true });

const getFlags = () => {
    const flags = Object.keys(flagsGlob).reduce((acc, key) => {
        const newKey = key.split('/').pop()?.split('.').shift() || '';
        acc[newKey] = (flagsGlob[key] as { default: string }).default;
        return acc;
    }, {});
    return flags;
};

export interface LanguageSelectProps {
    menuState?: boolean;
}
interface IconStyles {
    icon: string;
    style: any;
}
const useStyles = makeStyles((theme) => ({
    selectWrapper: {
        display: 'flex',
    },
    selectStyles: {
        border: `1px solid ${theme.palette.grayVariants[300]}`,
        marginTop: '0',
        '&::before': { display: 'none' },
        '&::after': { display: 'none' },
        '& .MuiSelect-icon': { display: 'none' },
        '& .MuiSelect-select': {
            gap: '12px',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '24px',
            padding: '10px 16px',
            display: 'flex',
        },
        '&:hover': {
            backgroundColor: theme.palette.grayVariants[100],
            borderRadius: '8px',
        },
        boxShadow: newShadows.xs,
        borderRadius: '8px',
        position: 'relative',
    },
    menuItemStyles: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 2px',
        flex: 1,
    },
    menuItemRoot: {
        alignItems: 'center',
        height: '42px',
        padding: '9px 10px',
        '&.Mui-selected': {
            backgroundColor: theme.palette.grayVariants[50],
            borderRadius: '6px',
            '&:hover': {
                borderRadius: '6px',
                backgroundColor: theme.palette.grayVariants[50],
            },
        },
        '&:hover': {
            borderRadius: '6px',
            backgroundColor: theme.palette.grayVariants[50],
        },
    },
    checkIcon: { width: '24px', height: '24px', marginLeft: 'auto', ...isRtl({ marginRight: 'auto', marginLeft: 0 }) },
    listPadding: {
        padding: '4px 6px',
        width: '184px',
        boxShadow: newShadows.lg,
    },
    menuItemsStyle: {
        display: 'flex',
        width: '100%',
        flex: 1,
        gap: 8,
        alignItems: 'center',
    },
    tooltip: {
        width: '100%',
    },
    t_name: {
        ...isRtl({
            textAlign: 'right',
        }),
    },
}));

export const LanguageSelect: React.FunctionComponent<LanguageSelectProps> = (props) => {
    const classes = useStyles();
    const { menuState } = props;
    const {
        i18n: { language },
    } = useTranslation();
    const theme = useTheme();

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

    const flags = getFlags();
    const selectRef = React.useRef(null);

    const { languages, numberOfLocals } = useMemo(() => {
        const loc: {
            code: string;
            display_name: string;
            full_name: string;
            dir: string;
        }[] = Object.values(locales ?? {});
        const orderedLanguages = loc?.reduce(
            (acc: any, locale: any) => {
                const countryCode = LanguageToCountry[locale.code];
                const flagIcon = flags[countryCode] || flags['GB'];
                const isActive = i18next.language === locale.code;

                acc.languages.push({
                    icon: flagIcon,
                    t_name: locale.full_name,
                    isActive,
                    code: locale.code,
                });

                acc.numberOfLocals += 1;

                return acc;
            },
            { languages: [], numberOfLocals: 0 }
        );

        orderedLanguages.languages = orderedLanguages.languages.sort((a: any, b: any) =>
            a.isActive ? -1 : b.isActive ? 1 : 0
        );

        return orderedLanguages;
    }, [flags]);

    const shouldRenderSelect = useMemo(() => numberOfLocals > 1, [numberOfLocals]);

    const handleChange = useCallback(
        (event) => {
            setSelectedLanguage(event.target.value);
            if (event.target.value !== language) {
                i18n.changeLanguage(event.target.value);
            }
        },
        [language]
    );

    useEffect(() => {
        setSelectedLanguage(language);
    }, [language]);

    const renderValue = useCallback(
        (selectedValue: any) => {
            const selectedLang = languages.find((lang) => lang.code === selectedValue);
            return selectedLang ? <LanguageSelectIcon language={selectedLang} /> : null;
        },
        [languages]
    );

    return shouldRenderSelect ? (
        <div className={classNames({ [classes.selectWrapper]: menuState })}>
            <FormControl>
                <Select
                    inputRef={selectRef}
                    className={classes.selectStyles}
                    labelId="language-select"
                    key={`language-select_sideMenu${menuState}`}
                    value={selectedLanguage}
                    open={languageDropdownOpen}
                    onOpen={() => setLanguageDropdownOpen(true)}
                    onClose={() => setLanguageDropdownOpen(false)}
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                marginTop: '5px',
                            },
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: isRtl() ? 'left' : 'right',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: isRtl() ? 'left' : 'right',
                        },
                        getContentAnchorEl: null,
                        MenuListProps: {
                            className: classes.listPadding,
                        },
                    }}
                    renderValue={renderValue}
                >
                    {languages.map((language) => (
                        <MenuItem
                            key={`menuItem_${language.code}`}
                            value={language.code}
                            classes={{ root: classes.menuItemRoot, selected: classes.menuItemRoot }}
                        >
                            <ConditionalWrapper
                                condition={!menuState && !isMobile()}
                                wrapper={(wrappedChildren) => (
                                    <Tooltip
                                        tooltipRootClass={classes.tooltip}
                                        title={language.t_name}
                                        placement={theme.direction === 'rtl' ? 'left' : 'right'}
                                    >
                                        {wrappedChildren}
                                    </Tooltip>
                                )}
                            >
                                <div className={classes.menuItemStyles}>
                                    <div className={classes.menuItemsStyle}>
                                        <LanguageSelectIcon language={language} />

                                        {languageDropdownOpen && (
                                            <Typography className={classes.t_name} variant="tSmallMedium">
                                                {language.t_name}
                                            </Typography>
                                        )}
                                    </div>
                                    {languageDropdownOpen && selectedLanguage === language.code && (
                                        <Icon name={IconsType.CheckSvg} className={classes.checkIcon} />
                                    )}
                                </div>
                            </ConditionalWrapper>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    ) : null;
};

export default React.memo(LanguageSelect);

interface LanguageSelectIconProps {
    language: any;
}
const useStyles2 = makeStyles((theme) => ({
    iconStyles: (prop: IconStyles) => {
        return {
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundPosition: 'center',
            backgroundImage: `url(${prop?.icon})`,
            flexShrink: 0,
            ...prop.style,
        };
    },
}));
function LanguageSelectIcon({ language }: LanguageSelectIconProps) {
    const style = {
        backgroundImage: `url("${language.icon}")`,
    };

    const classes = useStyles2({ icon: language.icon, style });
    return <div className={classes.iconStyles} />;
}
