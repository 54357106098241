import { makeStyles } from '@material-ui/core';
import { customGap, isTablet, customBreakPoint, isMobile } from 'helpers/device_helper';

export default makeStyles((theme) => ({
    app_bar_root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        justifyContent: 'space-between',
    },
    header_title_sector: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...customGap(theme.spacing(3), 'horizontal'),
        color: theme.palette.grayVariants[700],
    },
    header_actions_sector: {
        display: 'flex',
        flexDirection: 'row',
        ...customGap(theme.spacing(3), 'horizontal'),
    },
    header_container: {
        width: '100%',
        height: '74px',
        display: 'grid',
        padding: `0 ${theme.spacing(5)}rem`,
        gridTemplateColumns: 'auto auto',
        borderBottom: `0.1rem solid ${theme.palette.action.disabledBackground}`,
        ...isTablet({
            gridTemplateColumns: '2fr 1fr',
            borderBottom: 'unset',
        }),
        ...customBreakPoint(1650, {
            gridTemplateColumns: '3fr 1fr',
        }),
    },

    logo_position_Left: {
        justifyContent: 'flex-start',
    },
    logo_position_Centered: {
        justifyContent: 'center',
    },
    logo_position_Right: {
        justifyContent: 'flex-end',
    },

    header_without_border: {
        borderBottom: '0',
    },
    header_container__private: {
        width: '100%',
        height: '74px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `0.1rem solid ${
            theme.palette.type === 'dark' ? theme.palette.grayVariants[300] : theme.palette.action.disabledBackground
        }`,
        padding: theme.palette.featuresFlags['prop'] ? '0px 16px' : '16px',
        ...(theme.palette.featuresFlags['prop']
            ? customGap(theme.spacing(0), 'horizontal')
            : customGap(theme.spacing(3), 'horizontal')),
        ...isTablet({
            gridTemplateColumns: '2fr 1fr',
            borderBottom: theme.palette.featuresFlags['prop']
                ? `0.1rem solid ${
                      theme.palette.type === 'dark'
                          ? theme.palette.grayVariants[300]
                          : theme.palette.action.disabledBackground
                  }`
                : 'unset',
        }),
        ...isMobile({
            borderBottom: 'none',
        }),
    },
    header_left_section: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        ...isTablet({
            justifyContent: theme.palette.featuresFlags['prop'] ? 'inherit' : 'space-between',
        }),
        ...isMobile({
            justifyContent: theme.palette.featuresFlags['prop'] ? 'space-between' : 'inherit',
        }),
    },
    header_right_section: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'flex-end',
        gap: '16px',
    },
}));
