export const LanguageToCountry = {
    af: 'ZA',
    am: 'ET',
    ar: 'SA',
    az: 'AZ',
    be: 'BY',
    bg: 'BG',
    bn: 'BD',
    br: 'BR',
    bs: 'BA',
    ca: 'ES',
    cs: 'CZ',
    cy: 'GB',
    da: 'DK',
    de: 'DE',
    dv: 'MV',
    el: 'GR',
    en: 'GB',
    es: 'ES',
    et: 'EE',
    eu: 'ES',
    fa: 'IR',
    fi: 'FI',
    fr: 'FR',
    gl: 'ES',
    ha: 'NG',
    he: 'IL',
    hi: 'IN',
    hr: 'HR',
    hu: 'HU',
    hy: 'AM',
    id: 'ID',
    is: 'IS',
    it: 'IT',
    ja: 'JP',
    ka: 'GE',
    kk: 'KZ',
    km: 'KH',
    ko: 'KR',
    ku: 'IQ',
    ky: 'KG',
    lt: 'LT',
    lv: 'LV',
    mk: 'MK',
    ml: 'IN',
    mn: 'MN',
    mr: 'IN',
    ms: 'MY',
    nb: 'NO',
    nl: 'NL',
    nn: 'NO',
    no: 'NO',
    pl: 'PL',
    ps: 'AF',
    pt: 'PT',
    ro: 'RO',
    ru: 'RU',
    sd: 'PK',
    sk: 'SK',
    sl: 'SI',
    so: 'SO',
    sq: 'AL',
    sr: 'RS',
    sv: 'SE',
    sw: 'TZ',
    ta: 'LK',
    tg: 'TJ',
    th: 'TH',
    tr: 'TR',
    tt: 'RU',
    ug: 'CN',
    uk: 'UA',
    ur: 'PK',
    uz: 'UZ',
    vi: 'VN',
    zh: 'CN',
    'pt-br': 'BR',
};
