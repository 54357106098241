import { getThemeFromLocalStorage } from 'helpers/local_storage';
import { ESystemTypes } from './actionsTypes';
import { SystemReducerState, TimeFrames } from './interfaces';
import { isDesktop } from 'helpers/device_helper';
import config from 'config/common';

const initialState: SystemReducerState = {
    loader: true,
    theme: !config.featuresFlags['disableDarkMode'] ? getThemeFromLocalStorage() : 'light',
    sideMenuState: false,
    dataLoader: false,
    countries: [],
    currencies: {
        real: [],
        demo: [],
    },
    snackbarIsOpen: false,
    snackbarObject: null,
    filters: {},
    filterState: true,
    scrollToTable: { state: false, items: null },
    walkThoughStatus: !!localStorage.getItem('firstLoad'),
    bottomNavBar: !isDesktop(),
    deviceType: '',
    educationPopup: false,
};

const systemReducer = (state = initialState, { type, payload }: { type: ESystemTypes; payload: any }) => {
    switch (type) {
        case ESystemTypes.SET_SELECTED_TIME_FRAME:
            return {
                ...state,
                timeFrame: payload,
            };
        case ESystemTypes.SET_CHART_TYPE:
            return {
                ...state,
                chartType: payload,
            };
        case ESystemTypes.START_LOADER:
            return {
                ...state,
                loader: true,
            };
        case ESystemTypes.STOP_LOADER:
            return {
                ...state,
                loader: false,
            };
        case ESystemTypes.TOGGLE_THEME:
            return {
                ...state,
                theme: payload,
            };
        case ESystemTypes.TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuState: !state.sideMenuState,
            };
        case ESystemTypes.GET_COUNTRIES_START:
            return {
                ...state,
                dataLoader: true,
            };
        case ESystemTypes.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                dataLoader: false,
                countries: payload,
            };
        case ESystemTypes.GET_COUNTRIES_FAIL:
            return {
                ...state,
                dataLoader: false,
            };
        case ESystemTypes.GET_CURRENCIES_START:
            return {
                ...state,
                dataLoader: true,
            };
        case ESystemTypes.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                dataLoader: false,
                currencies: payload,
            };
        case ESystemTypes.GET_CURRENCIES_FAIL:
            return {
                ...state,
                dataLoader: false,
            };

        case ESystemTypes.DEVICE_SIZE:
            return {
                ...state,
                deviceType: payload,
            };
        case ESystemTypes.TOGGLE_EDUCATION_POPUP:
            return {
                ...state,
                educationPopup: !state.educationPopup,
            };

        case ESystemTypes.RESET_APP: {
            return {
                ...state,
                loader: false,
                sideMenuState: false,
                timeFrame: TimeFrames['5M'],
                chartBars: [],
                chartBarsLoading: false,
                popupIframe: undefined,
                flowGuardPopup: false,
                educationPopup: false,
                currencies: {
                    real: [],
                    demo: [],
                },
            };
        }

        case ESystemTypes.OPEN_SNACKBAR:
            return {
                ...state,
                snackbarIsOpen: true,
                snackbarObject: payload,
            };
        case ESystemTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                snackbarIsOpen: false,
                snackbarObject: null,
            };

        case ESystemTypes.DOWNLOAD_CSV:
            return { ...state };
        case ESystemTypes.TOGGLE_FILTER_STATE:
            return { ...state, filterState: !state.filterState };
        case ESystemTypes.UPDATE_FILTERS:
            return { ...state, filters: { ...payload } };
        case ESystemTypes.SCROLL_TO_TABLE: {
            return { ...state, scrollToTable: payload };
        }
        case ESystemTypes.COMPLETED_WALK_THROUGH: {
            return { ...state, walkThoughStatus: payload };
        }
        case ESystemTypes.TOGGLE_BOTTOM_NAV_BAR:
            return {
                ...state,
                bottomNavBar: payload,
            };
        default:
            return state;
    }
};

export default systemReducer;
