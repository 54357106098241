import { FormComponents, FormInput, ValidationScheme } from 'components/FormGenerator';
import { AccountInfoParsed, ContactUsRequest } from 'services/generatedClientFromSwagger/models';
import { user_contactUs } from 'store/user/actions';
import { user_userInfo } from 'store/user/selectors';
import Yup from 'yupSetup';
import config from 'config/common';
import { TFunction } from 'i18next';
export interface ClientSupportProps {
    userInfo: AccountInfoParsed;
    contactUs: (payload: ContactUsRequest) => Promise<boolean>;
}

export const mapStateToProps = (state) => ({
    userInfo: user_userInfo(state),
});

export const mapDispatchToProps = {
    contactUs: user_contactUs,
};

const defaultSubjectOptions = [
    {
        label: 'client-support:subjectSelectOptions.option1',
        value: 'Deposit/withdrawal related questions',
    },
    {
        label: 'client-support:subjectSelectOptions.option2',
        value: 'Verification related questions',
    },
    {
        label: 'client-support:subjectSelectOptions.option3',
        value: 'Trading related questions',
    },
    {
        label: 'client-support:subjectSelectOptions.option4',
        value: 'Technical issues',
    },
    {
        label: 'client-support:subjectSelectOptions.option5',
        value: 'Call back request',
    },
    {
        label: 'client-support:subjectSelectOptions.option7',
        value: 'Request a challenge refund',
    },
    {
        label: 'client-support:subjectSelectOptions.option6',
        value: 'Other',
    },
];

const getOptions = (t: TFunction) => {
    return config?.clientSupportSubjectSelectOptions?.length
        ? config.clientSupportSubjectSelectOptions
              .filter((option) => !!option)
              .map((option) => ({
                  label: `client-support:subjectSelectOptions.${option}`,
                  value: t(`client-support:subjectSelectOptions.${option}`, 'en') as string,
              }))
        : defaultSubjectOptions;
};

export const clientSupportFormInputsScheme: ({ t }: { t: TFunction }) => Array<FormInput> = ({ t }) => {
    const options = getOptions(t);

    return [
        {
            id: 'subject',
            type: FormComponents.select,
            labelT: 'common:fields.subject.label',
            placeholderT: 'common:fields.subject.placeholder',
            selectProps: {
                options,
            },
        },
        {
            id: 'yourMessage',
            type: FormComponents.textfield,
            labelT: 'common:fields.yourMessage.label',
            placeholderT: 'common:fields.yourMessage.placeholder',
            textfieldProps: {
                multiline: true,
                rows: 5,
            },
        },
    ];
};

export const clientSupportFormValidationsScheme: ValidationScheme = {
    subject: Yup.string().required(),
    yourMessage: Yup.string().required(),
};

export const clientSupportEmailFormInputsScheme: ({
    userInfo,
    t,
}: {
    userInfo: AccountInfoParsed;
    t: TFunction;
}) => Array<FormInput> = ({ userInfo, t }) => [
    {
        id: 'yourEmail',
        type: FormComponents.textfield,
        labelT: 'common:fields.yourEmail.label',
        placeholderT: 'common:fields.yourEmail.placeholder',
        textfieldProps: {
            defaultValue: userInfo.email,
        },
    },
    {
        id: 'subject',
        type: FormComponents.select,
        labelT: 'common:fields.subject.label',
        placeholderT: 'common:fields.subject.placeholder',
        selectProps: {
            defaultValue:
                (config.featuresFlags['depositRedirectToSupport'] && 'Deposit/withdrawal related questions') || '',
            options: getOptions(t),
        },
    },
    {
        id: 'yourMessage',
        type: FormComponents.textfield,
        labelT: 'common:fields.yourMessage.label',
        placeholderT: 'common:fields.yourMessage.placeholder',
        textfieldProps: {
            multiline: true,
            rows: 5,
        },
    },
];

export const clientSupportEmailFormValidationsScheme: ValidationScheme = {
    yourEmail: Yup.string().required().fixedEmail(),
    subject: Yup.string().required(),
    yourMessage: Yup.string().required(),
};

export const clientSupportPhoneFormInputsScheme: (t: TFunction) => Array<FormInput> = (t: TFunction) => [
    {
        id: 'phone',
        type: FormComponents.textfield,
        labelT: 'common:fields.phone.label',
        placeholderT: 'common:fields.phone.placeholder',
    },
    {
        id: 'subject',
        type: FormComponents.select,
        labelT: 'common:fields.subject.label',
        placeholderT: 'common:fields.subject.placeholder',
        selectProps: {
            options: getOptions(t),
        },
    },
    {
        id: 'yourMessage',
        type: FormComponents.textfield,
        labelT: 'common:fields.yourMessage.label',
        placeholderT: 'common:fields.yourMessage.placeholder',
        textfieldProps: {
            multiline: true,
            rows: 5,
        },
    },
];

export const clientSupportPhoneFormValidationsScheme: ValidationScheme = {
    phone: Yup.string().required().phone(),
    subject: Yup.string().required(),
    yourMessage: Yup.string().required(),
};
