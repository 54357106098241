export const getSearchParamFromUrl = (name: string): string | undefined => {
    try {
        const href = window.location.href;
        const asUrl = new URL(href);
        const value = asUrl.searchParams.get(name) || undefined;
        return value;
    } catch (error) {
        return undefined;
    }
};

export const getAppPathName = (): string => {
    return window.location.pathname;
};

export const getSearchParams = (): string => {
    return window.location.search;
};

export const getAllSearchParamsAsObject = (): { [key: string]: string } => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlParameters = {};
    for (let p of searchParams) {
        urlParameters[p[0]] = p[1];
    }
    return urlParameters;
};

export const removeURLParameter = (url, parameter) => {
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlparts[1].split(/[&;]/g);

        for (var i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
};
